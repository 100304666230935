import { isPlatformBrowser } from '@angular/common';
import { inject, Injectable, PLATFORM_ID } from '@angular/core';
import { RouteReuseStrategy, ActivatedRouteSnapshot, DetachedRouteHandle } from '@angular/router';

import { LayoutService } from '@offconon/shared/features/layout';

@Injectable({
  providedIn: 'root',
})
export class CustomRouteReuseStrategy extends RouteReuseStrategy {
  private storedRoutes = new Map<string, DetachedRouteHandle>();
  private layoutService = inject(LayoutService);
  private platformId = inject(PLATFORM_ID);

  override shouldDetach(route: ActivatedRouteSnapshot): boolean {
    return route.data['reuse'] === true;
  }
  override store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle | null): void {
    const id = this.getRouteId(route);
    if (id && handle) {
      this.storedRoutes.set(id, handle);
    }
  }
  override shouldAttach(route: ActivatedRouteSnapshot): boolean {
    const id = this.getRouteId(route);
    return !!id && !!this.storedRoutes.get(id);
  }
  override retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle | null {
    const id = this.getRouteId(route);
    return id ? this.storedRoutes.get(id) || null : null;
  }

  shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
    if (isPlatformBrowser(this.platformId) && this.layoutService.contentCardNativeElement) {
      this.layoutService?.contentCardNativeElement?.scrollTo({ top: 0 });
    }
    return future.routeConfig === curr.routeConfig;
  }

  public clearStoredRoutes(): void {
    this.storedRoutes.clear();
  }

  private getRouteId(route: ActivatedRouteSnapshot): string | null {
    if (!route.routeConfig || !route.routeConfig.path) {
      return null;
    }

    const path = route.routeConfig.path;
    const params = JSON.stringify(route.params);
    return `${path}-${params}`;
  }
}

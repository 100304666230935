import { isPlatformBrowser, registerLocaleData } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { inject, Injectable, PLATFORM_ID } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import locales from 'libs/shared/features/layout/layout/src/assets/locales.json';
import { variables } from 'libs/shared/utils/variables/variables';
import { catchError, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  private platformId = inject(PLATFORM_ID);

  public translate = inject(TranslateService);
  public http = inject(HttpClient);

  private langKey = variables.languageCodeStorageKey;
  private defaultLang = variables.defaultLanguage;

  setLanguage(lang: string) {
    if (isPlatformBrowser(this.platformId)) {
      localStorage.setItem(this.langKey, lang);
    }

    this.translate.use(lang);
  }

  getLanguage(): string {
    if (isPlatformBrowser(this.platformId)) {
      return localStorage.getItem(this.langKey) ?? this.defaultLang;
    }

    return this.defaultLang;
  }

  getDefaultLanguage(): string {
    return this.defaultLang;
  }

  isSupportedLanguage(lang: string): boolean {
    const supportedLangs = locales.locales;
    return supportedLangs.includes(lang);
  }

  getLanguageAndRegisterLocaleData() {
    let localeIsoCode = '';
    switch (this.getLanguage()) {
      case 'zh-cn':
      case 'zh-tw':
        localeIsoCode = 'zh';
        break;

      case 'dv':
        localeIsoCode = 'ar';
        break;
      case 'sm':
        localeIsoCode = 'en';
        break;

      default:
        localeIsoCode = this.getLanguage();
        break;
    }

    const localeUrl = `/assets/locales/${localeIsoCode}.json`;
    this.http
      .get(localeUrl)
      .pipe(
        catchError(() => {
          return of(null);
        }),
      )
      .subscribe((module: any) => {
        if (module?.[localeIsoCode]) {
          registerLocaleData(module?.[localeIsoCode], localeIsoCode);
        }
      });

    return localeIsoCode;
  }
}

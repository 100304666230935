import { CommonModule } from '@angular/common';
import { Component, EventEmitter, inject, Input, OnInit, Output, OnDestroy } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  ReactiveFormsModule,
  FormControl,
} from '@angular/forms';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import {
  createCustomFieldMulti,
  createCustomFieldTemplateSuccess as createCustomFieldMultiSuccess,
} from 'libs/shared/ui/custom-field-select-editor-render/src/lib/store/custom-field-template.actions';
import { AllCurrencyDropdownListComponent } from 'libs/shared/ui/form-elements/currency-select-list/src/lib/options/all-currency-dropdown-list/all-currency-dropdown-list.component';
import { ButtonModule } from 'primeng/button';
import { DividerModule } from 'primeng/divider';
import { FloatLabelModule } from 'primeng/floatlabel';
import { InputTextModule } from 'primeng/inputtext';
import { SelectModule } from 'primeng/select';

import {
  CommonCustomFieldCategory,
  Currency,
  PostCustomFieldCategoryMultiRequest,
  PostCustomFieldCategoryRequest,
  KeyWord,
} from '@offconon/core-api';
import { SiUnitsVirtualScrollDropdownCommonComponent } from '@offconon/shared/ui/si-units-virtual-scroll-dropdown-common';
import { SiteLanguageService } from '@offconon/shared/utils/services/site-language';

import { AddCustomFiledValueRowFormComponent } from '../../add-custom-filed-value-row-form/add-custom-filed-value-row-form.component';
import { RangeRowCustomFiledFormComponent } from '../range-row-custom-filed-form/range-row-custom-filed-form.component';

@Component({
  selector: 'offconon-custom-field-edit-form',
  templateUrl: './custom-field-edit-form.component.html',
  imports: [
    CommonModule,
    ReactiveFormsModule,
    SelectModule,
    InputTextModule,
    AllCurrencyDropdownListComponent,
    SiUnitsVirtualScrollDropdownCommonComponent,
    AddCustomFiledValueRowFormComponent,
    DividerModule,
    RangeRowCustomFiledFormComponent,
    ButtonModule,
    TranslateModule,
    FloatLabelModule,
  ],
})
export class CustomFieldEditFormComponent implements OnInit, OnDestroy {
  private fb = inject(FormBuilder);
  private translateService = inject(TranslateService);
  private siteLanguageService = inject(SiteLanguageService);

  @Input() system_data: any;
  @Input() parameters: any;
  @Input() workflow_place = false;
  @Output() formSavedEvent = new EventEmitter<any>();

  private store$ = inject(Store);
  private actions$ = inject(Actions);

  form: FormGroup;
  actual_language_id = 71;
  selected_language_id: any;
  language_parameters: any = {};

  new_result: CommonCustomFieldCategory;

  si_unit_type_filter_options: any;
  si_filter_access_options: any;
  si_filter_access_options_range: any;
  custom_type_options: any;

  rows: any = [];
  customValues: any = [];

  keyword_id: any;

  keyword_data: KeyWord;

  redirect_id: any;

  si_unit_name: any;
  loading = false;

  ngOnInit() {
    this.actual_language_id = this.siteLanguageService.actualSiteLanguageId();

    this.actions$.pipe(ofType(createCustomFieldMultiSuccess)).subscribe((data) => {
      this.formSavedEvent.emit(data.customFieldTemplate);
    });

    this.language_parameters = {
      translation_supported: true,
    };

    this.selected_language_id = this.system_data?.language_id
      ? this.system_data?.language_id
      : this.actual_language_id;

    this.si_filter_access_options = [
      {
        name: this.translateService.instant('Do not use international units of measurement'),
        code: PostCustomFieldCategoryRequest.SiFilterAccessEnum.No,
      },
      {
        name: this.translateService.instant('Use international units of measurement'),
        code: PostCustomFieldCategoryRequest.SiFilterAccessEnum.Yes,
      },
    ];

    this.si_filter_access_options_range = [
      {
        name: this.translateService.instant('Do not use international units of measurement'),
        code: PostCustomFieldCategoryRequest.SiFilterAccessEnum.No,
      },
      {
        name: this.translateService.instant('Use international units of measurement'),
        code: PostCustomFieldCategoryRequest.SiFilterAccessEnum.Yes,
      },

      {
        name: this.translateService.instant('Use integrated currency'),
        code: PostCustomFieldCategoryRequest.SiFilterAccessEnum.Currency,
      },
    ];

    this.si_unit_type_filter_options = [
      {
        name: this.translateService.instant('Length'),
        code: PostCustomFieldCategoryRequest.SiUnitTypeFilterEnum.Length,
      },
      {
        name: this.translateService.instant('Weight'),
        code: PostCustomFieldCategoryRequest.SiUnitTypeFilterEnum.Weight,
      },
      {
        name: this.translateService.instant('Volume'),
        code: PostCustomFieldCategoryRequest.SiUnitTypeFilterEnum.Volume,
      },
      {
        name: this.translateService.instant('Area'),
        code: PostCustomFieldCategoryRequest.SiUnitTypeFilterEnum.Area,
      },
      {
        name: this.translateService.instant('Time'),
        code: PostCustomFieldCategoryRequest.SiUnitTypeFilterEnum.Time,
      },
      {
        name: this.translateService.instant('Frequency'),
        code: PostCustomFieldCategoryRequest.SiUnitTypeFilterEnum.Frequency,
      },
      {
        name: this.translateService.instant('Speed'),
        code: PostCustomFieldCategoryRequest.SiUnitTypeFilterEnum.Speed,
      },
    ];

    this.custom_type_options = [
      {
        name: this.translateService.instant('Text input field'),
        code: PostCustomFieldCategoryRequest.CustomTypeEnum.Text,
      },
      {
        name: this.translateService.instant('Radio button'),
        code: PostCustomFieldCategoryRequest.CustomTypeEnum.Radio,
      },
      {
        name: this.translateService.instant('Checkbox'),
        code: PostCustomFieldCategoryRequest.CustomTypeEnum.Checkbox,
      },
      {
        name: this.translateService.instant('Dropdown list'),
        code: PostCustomFieldCategoryRequest.CustomTypeEnum.Dropdown,
      },
      {
        name: this.translateService.instant('Multiselect list'),
        code: PostCustomFieldCategoryRequest.CustomTypeEnum.MultiSelect,
      },
      {
        name: this.translateService.instant('Number input field'),
        code: PostCustomFieldCategoryRequest.CustomTypeEnum.InputNumber,
      },
      {
        name: this.translateService.instant('Range'),
        code: PostCustomFieldCategoryRequest.CustomTypeEnum.Range,
      },
    ];

    if (this.system_data?.id || !this.system_data.range_values) {
      this.system_data.custom_field_values.forEach((element: any) => {
        this.customValues.push(element.translations[0].name);
        this.rows.push(element.translations[0].name);
      });
    }
    this.form = this.fb.group({
      id: [this.system_data?.id],
      business_id: [
        this.system_data?.business_id
          ? this.system_data?.business_id
          : this.parameters?.business_id,
      ],
      name: [
        this.system_data?.keyword.translations[0]?.translation,
        [Validators.maxLength(85), Validators.minLength(2), Validators.required],
      ],
      category_template_id: [this.system_data?.category_template?.id],
      logo_path: [this.system_data?.logo_path],
      icon_path: [this.system_data?.icon_path],
      description: [this.system_data?.keyword.translations[0]?.description],
      prefix: [this.system_data?.prefix],
      suffix: [this.system_data?.suffix],
      custom_type: [
        this.system_data?.custom_type
          ? this.system_data?.custom_type
          : PostCustomFieldCategoryRequest.CustomTypeEnum.Dropdown,
        Validators.required,
      ],
      si_filter_access: [
        this.system_data?.si_filter_access
          ? this.system_data?.si_filter_access
          : PostCustomFieldCategoryRequest.SiFilterAccessEnum.No,
      ],
      si_unit_type_filter: [
        this.system_data?.si_unit_type_filter
          ? this.system_data?.si_unit_type_filter
          : PostCustomFieldCategoryRequest.SiUnitTypeFilterEnum.Weight,
      ],
      custom_field_values: [this.customValues ?? []],
      language_id: [this.selected_language_id],
      si_unit_id: [this.system_data?.si_unit_id],
      privacy_type: ['public'], //private
      range_values: [this.system_data?.range_values ?? []],
      currency: [this.system_data?.currency],
    });
  }

  get languageFormControl() {
    return this.form.get('language_id') as FormControl;
  }

  trackByIndex(index: number, item: any): any {
    return index;
  }

  addRow() {
    let row = '';
    this.rows.push(row);
    this.customValues.push(row);
    this.ruleChanged();
  }
  deleteRow(index: any) {
    this.rows.splice(index, 1);
    this.customValues.splice(index, 1);
    this.ruleChanged();
  }
  ruleChanged() {
    this.form.patchValue({
      custom_field_values: this.customValues,
    });
  }

  ngOnDestroy(): void {
    this.form.patchValue({
      custom_field_values: null,
    });
  }

  onChange(sitype: any) {
    if (sitype) {
      this.form.patchValue({
        si_unit_type_filter: sitype.value,
      });
    }
  }

  resetSiUnitName() {
    this.si_unit_name = undefined;
  }

  setSiUnit(si: any) {
    if (si) {
      this.form.patchValue({
        si_unit_id: si.id,
      });

      this.si_unit_name = si.name;
    }
  }

  setCurrency(currency: Currency) {
    if (currency) {
      this.form.patchValue({
        currency: currency.iso_code,
      });

      this.si_unit_name = currency.iso_code;
    }
  }

  submit() {
    this.loading = true;
    let customFieldValues = this.form.value.custom_field_values;

    if (
      this.form.get('custom_type')?.value === PostCustomFieldCategoryRequest.CustomTypeEnum.Dropdown
    ) {
      for (let i = 0; i < customFieldValues.length; i++) {
        const control = customFieldValues[i];
        if (!control || control.trim() === '') {
          if (customFieldValues.length > 1) {
            customFieldValues.splice(i, 1);
            i--;
          } else {
            customFieldValues[i] = 'No data given';
          }
        }
      }
    }

    const customFieldTemplate: PostCustomFieldCategoryMultiRequest = {
      keyword: {
        name: this.form.value.name,
        language_id: this.form.value.language_id,
        description: this.form.value.description ?? undefined,
      },
      bulk_translations: customFieldValues
        ? {
            translations: customFieldValues,
            language_id: this.form.value.language_id,
          }
        : undefined,
      custom_field: {
        custom_type: this.form.value.custom_type,
        si_filter_access: this.form.value.si_filter_access,
        privacy_type: this.form.value.privacy_type,
        si_unit_type_filter: this.form.value.si_unit_type_filter,
        si_unit_id: this.form.value.si_unit_id,
        currency: this.form.value.currency,
        id: this.system_data?.id,
        category_template_id: this.form.value.category_template_id,
        logo_path: this.form.value.logo_path,
        icon_path: this.form.value.icon_path,
        business_id: this.form.value.business_id,
        keyword_id: this.keyword_id,
        language_id: this.form.value.language_id,
        range_values: this.form.value.range_values,
      },
      prefix: this.form.value.prefix ?? undefined,
      suffix: this.form.value.suffix ?? undefined,
    };

    this.store$.dispatch(createCustomFieldMulti({ customFieldTemplate }));
    this.loading = false;
  }
}

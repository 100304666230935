import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';

import { HeaderMenuHelperService } from '@offconon/shared/utils/services/header-menu-helper';

import { GeneralSearchModuleComponent } from '../main/general-search-module.component';

@Component({
  selector: 'offconon-layout-search',
  imports: [TranslateModule, ButtonModule, GeneralSearchModuleComponent],
  templateUrl: './layout-search.component.html',
})
export class LayoutSearchComponent implements OnInit {
  private destroyRef = inject(DestroyRef);
  private headerMenuHelperService = inject(HeaderMenuHelperService);
  search_title: any;

  general_search = true;
  module_data: any;

  general_search_parameters: any = {};

  ngOnInit() {
    this.general_search_parameters = {
      header_main_search: true,
    };

    this.headerMenuHelperService.headerModuleSearch$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (data: any) => {
          if (data) {
            this.search_title = data.profile_type;
            this.module_data = data;
            this.general_search = false;
          } else {
            this.module_data = null;
            this.general_search = true;
          }
        },
      });
  }
}

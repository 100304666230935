import { Injectable, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, take } from 'rxjs';

import { AuthService } from '@offconon/core/features/auth';
import { LoginResponse } from '@offconon/core-api';
import { EmployeeRuleByCoWorkerRule } from '@offconon/core-api';
import { isRbacListContainsRbacRule } from '@offconon/workroom/features/rbac-module';

@Injectable({
  providedIn: 'root',
})
export class HeaderMenuHelperService {
  header_module_menu_parameters: any = {};
  header_module_add_parameters: any = {};
  moduleEnumList: any;

  public user: LoginResponse | null = null;
  private authService = inject(AuthService);
  private store$ = inject(Store);

  private translateService = inject(TranslateService);

  private headerModuleMenuSubject = new BehaviorSubject(null);
  headerModuleMenu$ = this.headerModuleMenuSubject.asObservable();

  private headerModuleAddSubject = new BehaviorSubject(null);
  headerModuleAdd$ = this.headerModuleAddSubject.asObservable();

  private headerModuleSearchSubject = new BehaviorSubject(null);
  headerModuleSearch$ = this.headerModuleSearchSubject.asObservable();

  setHeaderModuleData(menuData: any) {
    this.headerModuleMenuSubject.next(menuData);
  }

  setHeaderAddData(menuData: any) {
    this.headerModuleAddSubject.next(menuData);
  }

  setHeaderearchData(menuData: any) {
    this.headerModuleSearchSubject.next(menuData);
  }

  isSubMenuVisible(accessType: EmployeeRuleByCoWorkerRule.AccessTypeEnum) {
    let state = false;

    this.store$
      .select(isRbacListContainsRbacRule(accessType))
      .pipe(take(1))
      .subscribe((res) => {
        state = res;
      });

    return state;
  }
}

import { isPlatformServer } from '@angular/common';
import {
  HttpClient,
  provideHttpClient,
  withFetch,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { inject, NgModule, PLATFORM_ID, TransferState } from '@angular/core';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import * as fs from 'fs';
import { Observable, of } from 'rxjs';

// Custom loader for SSR
export class CustomLoader implements TranslateLoader {
  private platformId = inject(PLATFORM_ID);

  constructor(
    private http: HttpClient,
    private transferState: TransferState,
  ) {}

  getTranslation(lang: string): Observable<any> {
    if (isPlatformServer(this.platformId)) {
      try {
        return new Observable((observer) => {
          const assetsFolder = `${process.cwd()}/dist/apps/core/browser/assets/translate/i18n`;

          const jsonData = JSON.parse(fs.readFileSync(`${assetsFolder}/${lang}.json`, 'utf8'));

          observer.next(jsonData);
          observer.complete();
        });
      } catch (error) {
        console.error('Translation file read error:', error);
        return of({});
      }
    } else {
      return this.http.get(`./assets/translate/i18n/${lang}.json`);
    }
  }
}
@NgModule({
  declarations: [],
  exports: [TranslateModule],
  imports: [
    TranslateModule.forRoot({
      extend: true,
      isolate: false,
      loader: {
        provide: TranslateLoader,
        useFactory: (http: HttpClient) =>
          new TranslateHttpLoader(http, './assets/translate/i18n/', '.json'),
        deps: [HttpClient],
      },
    }),
  ],
  providers: [provideHttpClient(withInterceptorsFromDi(), withFetch())],
})
export class SharedUtilsTranslateModule {}

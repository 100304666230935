import { inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class CustomFieldHelperService {
  private translateService = inject(TranslateService);

  customTypeEnums: any = {
    text: this.translateService.instant('Text input field'),
    radio: this.translateService.instant('Radio button'),
    checkbox: this.translateService.instant('Checkbox'),
    dropdown: this.translateService.instant('Dropdown list'),
    multi_select: this.translateService.instant('Multiselect list'),
    input_number: this.translateService.instant('Number input field'),
    range: this.translateService.instant('Range'),
  };
}

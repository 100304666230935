import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';

import { EnvironmentService } from './environment.service';

@Injectable({
  providedIn: 'root',
})
export class GlobalErrorHandlerService implements ErrorHandler {
  private messageService = inject(MessageService);
  private translateService = inject(TranslateService);
  private environmentService = inject(EnvironmentService);

  handleError(error: HttpErrorResponse) {
    if (!this.environmentService.environment.production) {
      console.error('An error occurred:', error);
    }
    if (!error?.error?.errors) return;
    error.error.errors.forEach((err: any) => {
      if (err?.error === 'No_rbac') {
        this.messageService.add({
          severity: 'error',
          summary: this.translateService.instant('You do not have access rights to this feature!'),
          detail: this.translateService.instant(
            'Please contact your system administrators to access this feature.',
          ),
        });
      } else {
        this.messageService.add({
          severity: 'error',
          summary: this.translateService.instant('Error'),
          detail: this.translateService.instant('Something went wrong. Please try again.'),
        });
      }
    });
  }
}

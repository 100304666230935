import { Injectable, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class SideBarService {
  translate = inject(TranslateService);

  user: any;

  constructor() {
    this.getMenus();
  }
  getMenus() {
    return [
      {
        name: 'Quick start',
        menuIcon: 'home',
        visible: true,
        elements: [
          {
            link: '/',
            icon: 'home',
            firstLine: 'Main page',

            activeUrl: '',
            visible: true,
          },

          {
            link: '/dashboard',
            icon: 'dashboard',
            firstLine: 'Dashboard',

            activeUrl: 'dashboard',
            visible: true,
          },
        ],
      },
      {
        name: 'Role-based access control',
        menuIcon: 'admin_panel_settings',
        visible: true,
        elements: [
          {
            link: '/rbac-admin',
            icon: 'security',
            firstLine: 'Access options',

            activeUrl: '',
            visible: true,
          },
        ],
      },
      {
        name: 'OffConOn Administration',
        menuIcon: 'engineering',
        visible: true,
        elements: [
          {
            link: '/applications-admin',
            icon: 'dashboard',
            firstLine: 'Applications',

            activeUrl: 'applications-admin',
            visible: true,
          },
          {
            link: '/academy-admin',
            icon: 'dashboard',
            firstLine: 'Academy module',

            activeUrl: 'academy-admin',
            visible: true,
          },

          {
            link: '/mr-ai-code-admin',
            icon: 'dashboard',
            firstLine: 'MR AI Code',

            activeUrl: 'mr-ai-code-admin',
            visible: true,
          },
          {
            link: '/legal-entity-types-admin',
            icon: 'dashboard',
            firstLine: 'Legal entity types',

            activeUrl: 'legal-entity-types-admin',
            visible: true,
          },

          {
            link: '/si-units-admin',
            icon: 'dashboard',
            firstLine: 'SI Units admin',

            activeUrl: 'si-units-admin',
            visible: true,
          },
          {
            link: '/timezone-admin',
            icon: 'dashboard',
            firstLine: 'Timezone admin',

            activeUrl: 'timezone-admin',
            visible: true,
          },
        ],
      },
      {
        name: 'Financial management',
        menuIcon: 'folder_copy',
        visible: true,
        elements: [
          {
            link: '/bank-database-management',
            icon: 'dashboard',
            firstLine: 'Bank database admin',

            activeUrl: 'bank-database-management',
            visible: true,
          },
          {
            link: '/payment-providers-management',
            icon: 'dashboard',
            firstLine: 'Payment providers',

            activeUrl: 'payment-providers',
            visible: true,
          },
          {
            link: '/payment-on-spot-management',
            icon: 'dashboard',
            firstLine: 'Payment on spot',

            activeUrl: 'payment-on-spot-admin',
            visible: true,
          },
        ],
      },
      {
        name: 'Categories',
        menuIcon: 'layers',
        visible: true,
        elements: [
          {
            link: '/category-template-admin',
            icon: 'dashboard',
            firstLine: 'Templates categories',

            activeUrl: 'category-template-admin',
            visible: true,
          },
          {
            link: '/cross-reference',
            icon: 'dashboard',
            firstLine: 'Cross reference',

            visible: true,
          },
        ],
      },

      {
        name: 'Sales partners',
        menuIcon: 'layers',
        visible: true,
        elements: [
          {
            link: '/sales-partner-commission',
            icon: 'dashboard',
            firstLine: 'Sales partner commission',

            activeUrl: 'sales-partner-commission',
            visible: true,
          },
        ],
      },

      {
        name: 'Email templates',
        menuIcon: 'library_books',
        visible: true,
        elements: [
          {
            link: '/system-email-templates-admin',
            icon: 'dashboard',
            firstLine: 'Email templates',

            activeUrl: 'system-email-templates-admin',
            visible: true,
          },
        ],
      },

      {
        name: 'Catalog',
        menuIcon: 'library_books',
        visible: true,
        elements: [
          {
            link: '/catalog/plant',
            icon: 'yard',
            firstLine: 'Plant catalog',

            visible: true,
          },

          {
            link: '/catalog/animal',
            icon: 'pets',
            firstLine: 'Animal catalog',

            visible: true,
          },
          {
            link: '/catalog/physical',
            icon: 'pallet',
            firstLine: 'Physical product catalog',

            visible: true,
          },
          {
            link: '/catalog/food',
            icon: 'restaurant',
            firstLine: 'Food and drink catalog',

            visible: true,
          },

          {
            link: '/catalog/bucket',
            icon: 'checklist',
            firstLine: 'Bucket list catalog',

            visible: true,
          },
        ],
      },

      {
        name: 'Custom fields',
        menuIcon: 'assignment',
        visible: true,
        elements: [
          {
            link: '/custom-fields',
            icon: 'dashboard',
            firstLine: 'Custom fields admin',

            activeUrl: 'custom-fields',
            visible: true,
          },
        ],
      },
      {
        name: 'CRM database of targeted potential customers',
        menuIcon: 'support_agent',
        visible: true,
        elements: [
          {
            link: '/crm-targeted-customers',
            icon: 'dashboard',
            firstLine: 'Contacts',

            activeUrl: 'crm-targeted-customers',
            visible: true,
          },
        ],
      },
      {
        name: 'Languages',
        menuIcon: 'language',
        visible: true,
        elements: [
          {
            link: '/languages-admin',
            icon: 'dashboard',
            firstLine: 'Languages',

            activeUrl: 'languages-admin',
            visible: true,
          },
        ],
      },
      {
        name: 'Locations',
        menuIcon: 'map',
        visible: true,
        elements: [
          {
            link: '/locations/regions',
            icon: 'dashboard',
            firstLine: 'Regions',

            activeUrl: 'locations',
            visible: true,
          },
          {
            link: '/locations/sub-regions',
            icon: 'dashboard',
            firstLine: 'Subregions',

            activeUrl: 'locations',
            visible: true,
          },
          {
            link: '/locations/countries',
            icon: 'dashboard',
            firstLine: 'Countries',

            activeUrl: 'locations',
            visible: true,
          },
          {
            link: '/locations/provinces',
            icon: 'dashboard',
            firstLine: 'Provinces',

            activeUrl: 'locations',
            visible: true,
          },
        ],
      },
      {
        name: 'Keywords',
        menuIcon: 'query_stats',
        visible: true,
        elements: [
          {
            link: '/keyword-admin',
            icon: 'dashboard',
            firstLine: 'Keywords',

            activeUrl: 'query_stats',
            visible: true,
          },
        ],
      },
      {
        name: 'Subscription prices',
        menuIcon: 'currency_yuan',
        visible: true,
        elements: [
          {
            link: '/subscription-prices-admin',
            icon: 'dashboard',
            firstLine: 'Subscription prices',

            activeUrl: 'subscription-prices-admin',
            visible: true,
          },

          {
            link: '/subscription-prices-admin/add-price',
            icon: 'dashboard',
            firstLine: 'Add subscription price',

            activeUrl: 'subscription-prices-admin',
            visible: true,
          },

          {
            link: '/subscription-prices-admin/add-ons-list',
            icon: 'dashboard',
            firstLine: 'Add-ons',

            activeUrl: 'subscription-prices-admin',
            visible: true,
          },
          {
            link: '/subscription-prices-admin/add-ons-create',
            icon: 'dashboard',
            firstLine: 'Add-ons create',

            activeUrl: 'subscription-prices-admin',
            visible: true,
          },
        ],
      },

      {
        name: 'Website / Application manager',
        menuIcon: 'app_shortcut',
        visible: true,
        elements: [
          {
            link: '/website-preview/site-preview',
            icon: 'call_to_action',
            firstLine: 'Sites',

            activeUrl: 'website-preview',
            visible: true,
          },
          {
            link: '/website-preview/page-preview',
            icon: 'description',
            firstLine: 'Pages',

            activeUrl: 'website-preview',
            visible: true,
          },
          {
            link: '/website-preview/widget-preview',
            icon: 'category',
            firstLine: 'Component templates',

            activeUrl: 'website-preview',
            visible: true,
          },
        ],
      },
      {
        name: 'Tax management',
        menuIcon: 'receipt_long',
        visible: true,
        elements: [
          {
            link: '/tax-admin',
            icon: 'dashboard',
            firstLine: 'Pre-made tax rules',

            activeUrl: 'tax-admin',
            visible: true,
          },
        ],
      },
      {
        name: 'Currency Exchange',
        menuIcon: 'currency_exchange',
        visible: true,
        elements: [
          {
            link: '/currency-exchange-rate',
            icon: 'dashboard',
            firstLine: 'Exchange margin',

            activeUrl: 'currency-exchange-rate',
            visible: true,
          },
        ],
      },
    ];
  }
}

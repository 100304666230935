import {AsyncPipe, CommonModule} from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  inject,
} from '@angular/core';
import {Validators} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import {ButtonModule} from 'primeng/button';
import {DividerModule} from 'primeng/divider';
import {BehaviorSubject, Observable, lastValueFrom} from 'rxjs';

import {SiUnits, SystemService} from '@offconon/core-api';
import {SharedUiVirtualscrollDropdownComponent} from '@offconon/shared/ui/shared-ui-virtualscroll-dropdown';
import {CommonSelectOption} from '@offconon/shared/ui/shared-ui-virtualscroll-multiselect';
import {
  CommonDropdownAndMultiselectService,
  DropdownData
} from "@offconon/shared/utils/services/common-dropdown-and-multiselect";


@Component({
  selector: 'offconon-si-units-virtual-scroll-dropdown-common',
  imports: [
    ButtonModule,
    CommonModule,
    DividerModule,
    SharedUiVirtualscrollDropdownComponent,
    AsyncPipe,
    TranslateModule,
  ],
  providers: [CommonDropdownAndMultiselectService],
  templateUrl: './si-units-virtual-scroll-dropdown-common.component.html',
  styles: ``,
})
export class SiUnitsVirtualScrollDropdownCommonComponent implements OnInit, OnChanges {
  @Input({required: true}) siUnitFormControl: any;
  @Input() unit_type: any;
  @Input() selectFirst = true;
  @Input() selectedId: number;
  @Output() selectedSiUnitEvent = new EventEmitter<any>();

  public commonDropdownAndMultiselectService = inject(CommonDropdownAndMultiselectService);
  private systemService = inject(SystemService);

  siUnitList$: Observable<CommonSelectOption[]>;

  dropdownData$ = new BehaviorSubject<
    DropdownData<{
      id?: number;
      name?: string;
      unit_type?: any;
    }>
  >({page: 1, pageSize: 50, dataList: []});

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['unit_type']) {
      this.dropdownData$.next({
        page: 1,
        pageSize: 50,
        searchJson: {
          unit_type: this.unit_type,
        },
        selectedId: undefined,
        dataList: [],
      });
    }
  }

  ngOnInit(): void {
    this.initializeComponent();

    this.commonDropdownAndMultiselectService.selectedOptions$.subscribe({
      next: (option: any) => {
        if (option) {
          this.siUnitFormControl.patchValue(option.id);
          this.selectedSiUnitEvent.emit(option);
        }
      },
    });
  }

  private initializeComponent() {
    this.siUnitList$ = this.commonDropdownAndMultiselectService.fetchData<
      SystemService,
      {
        id?: number;
        name?: string;
      }
    >({
      service: this.systemService,
      action: 'systemSiUnitsList',

      dropdownData: this.dropdownData$,
      mapData: (data: SiUnits[]) => {
        return data?.map((item) => {
          return {
            name: item?.name,
            unit_type: item.unit_type,
            id: item.id,
            additionalData: item,
          };
        });
      },
      selectFirst: this.selectFirst,
    });

    this.dropdownData$.next({
      page: 1,
      pageSize: 50,
      selectedId: this.selectedId,
      searchJson: {
        unit_type: this.unit_type,
      },
      dataList: [],
    });
  }

  updatesiUnitFormControl(states: CommonSelectOption[] = []) {
    const siUnitFormControl = this.siUnitFormControl;

    if (states?.length === 0) {
      siUnitFormControl.clearValidators();
      siUnitFormControl.disable();
    } else {
      siUnitFormControl.setValidators([Validators.required]);
      siUnitFormControl.enable();
    }
    siUnitFormControl.updateValueAndValidity();
  }

  select(event: any) {
    this.siUnitFormControl.patchValue(event.id);
    this.selectedSiUnitEvent.emit(event);
  }

  paginationAndSearchOutput(event: any) {
    lastValueFrom(this.siUnitList$).then((data) => {
      this.updatesiUnitFormControl(data);
    });
    this.commonDropdownAndMultiselectService.onDataChange(event, this.dropdownData$, {
      name: event.searchString,
      unit_type: this.unit_type,
    });
  }
}

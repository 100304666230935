import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'joinCategoryPath',
  standalone: true,
})
export class JoinCategoryPathPipe implements PipeTransform {
  transform(
    value: string[],
    iconClass: string = 'pi pi-chevron-right !text-xs',
    delimiter: string = ' ',
  ): string {
    if (!Array.isArray(value) || value.length <= 1) {
      return value.join(delimiter);
    }

    let result = '';
    for (let i = 0; i < value.length; i++) {
      result += value[i];
      if (i < value.length - 1) {
        result += `<i class="${iconClass} !text-xs "></i>${delimiter}`;
      }
    }

    return result;
  }
}

export const developEnvironment = {
  coreUrl: 'https://core.development.mraitech.com',
  workroomDomain: 'workroom.development.mraitech.com',
  coreDomain: 'core.development.mraitech.com',
  domainPreviewDomain: 'localhost:4204',
  coreApi: 'https://coreapi.development.mraitech.com',
  coreWebsocket: 'wss://coreapi.development.mraitech.com/ofcws',
  adminApi: 'https://adminapi.development.mraitech.com',
  jitsiDomain: 'video.mraitech.com',

  googleLoginProviderKey:
    '235695738921-kb6uhp4t8aq367tcmg5mcj4o5g7drdbq.apps.googleusercontent.com',
};

import { Injectable, inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { variables } from 'libs/shared/utils/variables/variables';
import { Observable } from 'rxjs';

import { UrlService } from '@offconon/shared/utils/services/url';

@Injectable({
  providedIn: 'root',
})
export class UrlGuard implements CanActivate {
  private urlService = inject(UrlService);
  private router = inject(Router);

  shareUrl: string = variables.shareUrl;

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (state.url.includes(this.shareUrl)) {
      const url = this.urlService.getSharedUrl(state.url);
      this.router.navigateByUrl(`/${url}`);
      return false;
    }

    return true;
  }
}

<div class="p-2">
  <p-panel [toggleable]="true" [collapsed]="collapsedStatus" [styleClass]="'w-full'">
    <ng-template #header>
      <div class="flex items-center gap-2 cursor-pointer">
        <span class="font-bold text-sm text-primary">
          {{ 'Specifications' | translate | truncate: truncate_number }}</span
        >
      </div>
    </ng-template>

    <ng-template #icons>
      <button class="p-panel-header-icon p-link mr-2" (click)="hideAll()">
        <span class="pi pi-file-edit text-primary"></span>
      </button>
    </ng-template>
    <div class="pb-3 pt-3">
      @if (show_all) {
        <div class="font-light text-center text-xs leading-6 mt-2 mb-3">
          {{ desc }}
        </div>

        <p-divider class="w-full" />
        @if ((loading$ | async) === true; as loading) {
          <offconon-skeleton-render-sample-wide-card [numberOfSkeletonCard]="1" />
        }
        @if (selectedCustomFieldList$ | async; as data) {
          @if (
            ((data.selectedCustomFieldList?.results &&
              data.selectedCustomFieldList.results?.length) ||
              0) > 0
          ) {
            <div class="mt-2">
              @for (customField of data.selectedCustomFieldList.results; track customField) {
                <offconon-selected-custom-field-editor-element
                  [selectedCustomField]="customField"
                  [is_editable]="is_editable"
                  (editClickEvent)="editClick($event)"
                  (deleteClickEvent)="confirmDelete($event, false)" />
              }
              @if ((data?.selectedCustomFieldList?.total_objects || 0) > 10) {
                <div class="flex justify-content-center flex-wrap pb-5 pt-5">
                  <div class="flex align-items-center justify-content-center">
                    <p-paginator
                      [styleClass]="(loading$ | async) ? 'pointer-events-none' : ''"
                      [rows]="data?.selectedCustomFieldList?.page_size || 10"
                      [totalRecords]="data?.selectedCustomFieldList?.total_objects || 10"
                      [rowsPerPageOptions]="[10, 20, 30]"
                      (onPageChange)="paginationChanged($event)" />
                  </div>
                </div>
              }
            </div>
          } @else {
            <div class="text-center mt-4">
              <div class="text-red-500 text-lg m-2 pb-3">
                {{ 'You have no saved specifications!' | translate }}
              </div>
            </div>
          }
        }
      } @else {
        <div class="flex justify-end flex-wrap">
          <button
            pButton
            type="button"
            icon="pi pi-times"
            class="p-button-rounded p-button-danger flex items-center justify-center"
            (click)="showAll()"></button>
        </div>
        <div class="mb-3 font-bold text-lg text-center">
          <span class="text-blue-600">
            {{ selectedCustomField ? ('Edit' | translate) : ('Create' | translate) }}</span
          >
        </div>

        <offconon-selected-custom-field-editor-form
          [wfSelectedCustomField]="selectedCustomField"
          [parameters]="parameters"
          (hideEvent)="hideSave($event)"
          (closeForm)="closeForm()" />
      }
    </div>
  </p-panel>
</div>

<p-confirmDialog
  key="customSelectedtemDialog"
  [breakpoints]="{ '960px': '75vw' }"
  [baseZIndex]="2"
  [appendTo]="'body'">
  <ng-template #headless let-message let-onAccept="onAccept" let-onReject="onReject">
    <div class="flex flex-col items-center p-8 bg-surface-0 dark:bg-surface-900 rounded">
      <span class="font-bold text-2xl block mb-2 mt-6">{{ message?.header }}</span>
      <p class="mb-0">{{ message?.message }}</p>
      <div class="flex items-center gap-2 mt-6">
        <p-button label="{{ 'Yes, I want to delete it.' | translate }}" (onClick)="onAccept()" />
        <p-button label="{{ 'Cancel' | translate }}" [outlined]="true" (onClick)="onReject()" />
      </div>
    </div>
  </ng-template>
</p-confirmDialog>

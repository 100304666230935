import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, mergeMap, of, switchMap, tap } from 'rxjs';

import { SystemService } from '@offconon/core-api';
import { ErrorService } from '@offconon/shared/utils/services/error';

import * as CustomFieldTemplateActions from './custom-field-template.actions';

@Injectable()
export class CustomFieldTemplateEffects {
  private actions$ = inject(Actions);
  private systemService = inject(SystemService);
  private errorService = inject(ErrorService);
  loadCustomFieldTemplate$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CustomFieldTemplateActions.fetchCustomFieldTemplate),
        mergeMap((payload) =>
          this.systemService
            .systemCustomFieldList(payload.page, payload.pageSize, payload.searchJson)
            .pipe(
              switchMap((paginatedWfSelectedCustomFieldList) => {
                return of(
                  CustomFieldTemplateActions.setLoaded({ loading: true }),
                  CustomFieldTemplateActions.fetchCustomFieldTemplateSuccess({
                    paginatedWfSelectedCustomFieldList,
                  }),
                  CustomFieldTemplateActions.setLoaded({ loading: false }),
                );
              }),
              catchError((error) => {
                this.errorService.showErrorMessage(error);
                return of(CustomFieldTemplateActions.loadCustomFieldTemplateFailure({ error }));
              }),
            ),
        ),
      ),
    { dispatch: true } as any,
  );
  loadSelectedCustomFieldTemplate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CustomFieldTemplateActions.fetchSelectedCustomFieldTemplate),
      mergeMap((action) =>
        this.systemService
          .systemSelectCustomFieldList(action.page, action.pageSize, action.searchJson)
          .pipe(
            switchMap((paginatedWfSelectedCustomFieldList) => {
              return [
                CustomFieldTemplateActions.setLoaded({ loading: true }),
                CustomFieldTemplateActions.fetchSelectedCustomFieldTemplateSuccess({
                  paginatedWfSelectedCustomFieldList,
                }),
                CustomFieldTemplateActions.setLoaded({ loading: false }),
              ];
            }),
            catchError((error) => {
              this.errorService.showErrorMessage(error);
              return of(CustomFieldTemplateActions.loadCustomFieldTemplateFailure({ error }));
            }),
          ),
      ),
    ),
  );
  selectCustomField$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CustomFieldTemplateActions.selectCustomField),
      mergeMap((action) =>
        this.systemService.systemSelectCustomFieldCreate(action.selectedCustomField).pipe(
          switchMap((paginatedWfSelectedCustomFieldList) => {
            return [
              CustomFieldTemplateActions.setLoaded({ loading: true }),
              CustomFieldTemplateActions.fetchSelectedCustomFieldTemplate({
                searchJson: {
                  service_id: action.catalogId,
                  service_type: 'catalog',
                },
              }),
              CustomFieldTemplateActions.setLoaded({ loading: false }),
            ];
          }),
          catchError((error) => {
            this.errorService.showErrorMessage(error);
            return of(CustomFieldTemplateActions.loadCustomFieldTemplateFailure({ error }));
          }),
        ),
      ),
    ),
  );
  createCustomFieldMulti$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CustomFieldTemplateActions.createCustomFieldMulti),
      mergeMap((payload) =>
        this.systemService.systemCustomFieldMultiCreate(payload.customFieldTemplate).pipe(
          switchMap((customFieldTemplate) =>
            of(
              CustomFieldTemplateActions.setLoaded({ loading: false }),
              CustomFieldTemplateActions.createCustomFieldTemplateSuccess({ customFieldTemplate }),
              CustomFieldTemplateActions.setLoaded({ loading: false }),
            ),
          ),
          catchError((error) => {
            this.errorService.showErrorMessage(error);

            return of(CustomFieldTemplateActions.loadCustomFieldTemplateFailure({ error }));
          }),
        ),
      ),
    ),
  );
  deleteCustomFieldTemplate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CustomFieldTemplateActions.deleteCustomField),
      mergeMap((payload) =>
        this.systemService.systemCustomFieldDestroy(payload.id).pipe(
          switchMap(() =>
            of(
              CustomFieldTemplateActions.setLoaded({ loading: true }),
              CustomFieldTemplateActions.deleteCustomFieldTemplateSuccess({ id: payload.id }),
              CustomFieldTemplateActions.setLoaded({ loading: false }),
            ),
          ),
          catchError((error) => {
            this.errorService.showErrorMessage(error);

            return of(CustomFieldTemplateActions.loadCustomFieldTemplateFailure({ error }));
          }),
        ),
      ),
    ),
  );
  private store = inject(Store);
  deleteSelectedCustomFieldTemplate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CustomFieldTemplateActions.deleteSelectedCustomField),
      mergeMap((payload) =>
        this.systemService.systemSelectCustomFieldDestroy(payload.id).pipe(
          switchMap(() => [
            CustomFieldTemplateActions.setLoaded({ loading: true }),
            CustomFieldTemplateActions.deleteSelectedCustomFieldTemplateSuccess({ id: payload.id }),
            CustomFieldTemplateActions.setLoaded({ loading: false }),
          ]),
          catchError((error) => {
            this.errorService.showErrorMessage(error);
            return of(CustomFieldTemplateActions.loadCustomFieldTemplateFailure({ error }));
          }),
        ),
      ),
      tap(() => {
        this.store
          .select(CustomFieldTemplateActions.fetchSelectedCustomFieldTemplate)
          .subscribe((updatedState) => {});
      }),
    ),
  );
}
